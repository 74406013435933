footer.footer{
    background: #0e0e0e;
    position: relative;
    min-height: 100%;
}

footer.footer .container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

footer.footer .container > div{
    flex-grow: 1;
    flex-basis: 0;
    padding: 3rem .9rem;
}

footer.footer .container h2{
    color: white;
}

footer.footer .newsletter .form-element{
    background: white;
    display: inline-block;
}

footer.footer .newsletter .form-element span{
    padding: .5rem .7rem;
    cursor: pointer;
}

footer.footer .column p{
    color: gray;
}

footer.footer .move-up{
    position: absolute;
    right: 6%;
    top: 50%;
}

footer.footer .move-up span{
    color: pink;
}

footer.footer .move-up span:hover{
    color: white;
    cursor: pointer;
}

h2 {
    padding-left: 0;
}

