#product{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.card{
    min-width: 300px;
    min-height: 400px;
    border: 1px solid #eee;
    overflow: hidden;
    padding: 10px;
    box-shadow: 2px 8px 20px #ddd;
    margin: 10px;
    transition: 0.5s linear;
}
.card:hover{
    box-shadow: none;
}
.card img{
    max-width: 300px;
    width: 100%;
    height: 100%;
    max-height: 300px;
    display: block;
    object-fit: cover;
}
.card h3{
    text-transform: uppercase;
    margin: 10px 0;
    font-size: 1.5rem;
}
.card h3 a{
    text-decoration: none;
    color: #333;
}
.card h3 a:hover{
    color: crimson;
}
.card span{
    color: crimson;
}
.card p{
    margin: 10px 0;
}
.card button{
    border: none;
    outline: none;
    background: #333;
    color: white;
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 15px 0;
}

h1 {
    font-size: 4rem;
    justify-content: center;
    text-align: center;
}

span {
    padding-left: 2rem;
}

.productSection {
    box-shadow: 0 0 5px #ccc;
    padding: 15px 10px;
}