.site-title{
    background: url('../../img/header.png');
    background-size: cover;
    max-width: 100%;
    height: 90vh;
    width: auto\9;
    display: flex;
    justify-content: center;
    background-position: center center;
    background-repeat:no-repeat;
}

.site-title .site-background{
    padding-top: 10rem;
    text-align: center;
    color:  #0088cc;
}

.site-title h1, h2, h3{
    margin: .3rem;
}

.site-title .btn{
    margin: 1.8rem;
    background: #0088cc;
}

.site-title .btn:hover{
    background: transparent;
    border: 1px solid #0088cc;
    color: #0088cc;
}

button.btn{
    border: none;
    border-radius: 2rem;
    padding: 1rem 3rem;
    font-size: 1rem;
    cursor: pointer;
    color: white;
}