.toolbar {
    position: fix;
    top: 0;
    left: 0;
    width: 100%;
    background: #0088cc;
    height: 56px;
}

.toolbar__logo {
    margin-left: 1rem;
}

.toolbar__logo a img{
    width: 85%;
    height: auto;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0;
}

.toolbar__navigation-items {
    height: 100%;
}

.toolbar__navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
}

.toolbar__navigation-items li {
    padding: 0 1rem;
}

.spacer {
    flex: 0.4;
}

.toolbar__navigation-items a {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    display: inline-block;
    height: 100%;
    padding-top: 13px;
}

.toolbar__navigation-items li:hover a,
.toolbar__navigation-items li:hover,
.toolbar__navigation-items a:active {
    color: #0088cc;
    background: white;
}

@media (max-width: 768px) {
    .toolbar__navigation-items {
        display: none;
    }
}

@media (min-width: 769px) {
    .toolbar__toggle-button {
        display: none;
    }

    
    .toolbar__logo {
        margin-left: 0rem;
    }
}