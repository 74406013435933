.gridContainer {
    max-width: 80%;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #777;
    line-height: 24px;
    margin: 0 0 20px;
}

h2 {
    font-size: 2.2em;
    font-weight: 300;
    line-height: 42px;
    margin: 0 0 32px 0;
}

h4 {
    color: #0088cc;
    display: inline-block;
    padding-bottom: 6px;
}

.svg-inline--fa {
    display: inline-block;
    font: normal normal normal 22px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
}

.conclusion{
    padding-top: 12px;
    padding-bottom: 42px;
}